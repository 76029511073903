import { Override } from "framer"

//Code override provided by Eleveight Supply. All rights reserved 2024.

export const Linear: Override = () => ({
    onTap: () => smoothScrollTo(0, 500, "Linear"),
})

export const EaseInOut: Override = () => ({
    onTap: () => smoothScrollTo(0, 1000, "EaseInOut"),
})

const easings = {
    Linear: (t, b, c, d) => (c * t) / d + b,
    EaseInOut: (t, b, c, d) => {
        if (t === 0) return b
        if (t === d) return b + c
        if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b
    },
}

function smoothScrollTo(endY, duration = 1000, easingType = "Linear") {
    const startY = window.scrollY || window.pageYOffset
    const distanceY = endY - startY
    const startTime = new Date().getTime()

    const animateScroll = (easingFunc) => {
        const timer = setInterval(() => {
            const time = new Date().getTime() - startTime
            if (time >= duration) {
                clearInterval(timer)
                window.scrollTo(0, endY)
                return
            }
            const newY = easingFunc(time, startY, distanceY, duration)
            window.scrollTo(0, newY)
        }, 1000 / 120)
    }

    if (easings[easingType]) {
        animateScroll(easings[easingType])
    } else {
        console.warn(
            `Easing function "${easingType}" not recognized. Falling back to Linear.`
        )
        animateScroll(easings.Linear)
    }
}
